<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <v-card height="200px">
                    <v-card-title>
                        ตั้งค่า Email ในการส่ง SMS
                    </v-card-title>
                    <v-divider class="pb-2"></v-divider>
                    <v-form ref="formEditEmail" @submit.prevent='updateEmail'>
                        <v-card-text>
                            <v-text-field v-model="email" filled label="Email" placeholder="hello@email.com"
                                          :rules="[emailValidator]"
                                          hide-details="auto">

                            </v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :loading="loading" :disabled="loading" type="submit">
                                บันทึก
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card height="200px">
                    <v-card-title>
                        แสกน QR Code ใช้ในการส่ง LINE
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text class="d-flex align-center justify-center" style="height: 70%">
                        <v-btn color="primary"
                               target="_blank"
                               :href="`https://notify-bot.line.me/oauth/authorize?response_type=code&client_id=gbIRDPyi8YyrJDhOS9CQI7&redirect_uri=${domain}&scope=notify&state=hello`">
                            ลิงค์เชื่อมต่อ LINE Notify
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { emailValidator } from '@core/utils/validation';
import { onMounted, ref } from "@vue/composition-api";
import { useRouter } from "@core/utils";
import { api } from '@/services/api'
import store from "@/store";
import router from "@/router";

export default {
    setup() {
        const email = ref('')
        const loading = ref(false)
        const formEditEmail = ref(null)
        const domain = ref('https://bizme24.com/administrator/setting')
        const route = useRouter()
        onMounted(() => {
            setTimeout(() => {
                if (route.route.value.query.code && route.route.value.query.state) {
                    sendCode(route.route.value.query.code)
                }
            }, 1000)
            getEmail()
        })

        const sendCode = async (code) => {
            const res = await api.put({
                path: '/admin/lineNotify',
                body: {
                    code: code
                }
            })
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            router.push('/administrator/setting')
        }

        const getEmail = async () => {
            const { data } = await api.get({
                path: '/admin/email'
            })
            email.value = data.user_email
        }

        const updateEmail = async () => {
            const isFormValid = await formEditEmail.value.validate()
            if (!isFormValid) return
            loading.value = true

            const res = await api.put({
                path: '/admin/email',
                body: {
                    user_email: email.value
                }
            })
            store.commit('app/ALERT', {
                message: res.message,
                color: res.response ? 'success' : 'error',
            })
            loading.value = false
        }


        return { emailValidator, email, loading, updateEmail, formEditEmail, domain }
    },

}
</script>

<style scoped lang="scss">

</style>
